@import "styles/variables";

.LinearProgress {
  display: block;
  width: 100%;
  height: 3px;
  position: relative;
  background-color: $color-bg;
  overflow: hidden;

  &__bar {
    width: 15%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: $color-primary;
    left: -15%;
    animation: linear infinite 1s load;
  }

  @keyframes load {
    0% {
      left: -15%;
    }

    100% {
      left: 100%;
    }
  }
}
