@import "styles/variables";

.Accordion {
  &__heading {
    display: flex;
    flex-direction: row;
    background-color: $color-bg;
    cursor: pointer;

    .title {
      padding: 16px;
      width: 100%;
    }

    .arrow {
      width: 48px;
      font-size: 0;
      display: flex;
      align-content: center;
      justify-content: center;
      user-select: none;

      img {
        width: 12px;
        transition: .5s;
      }
    }
  }

  &__body {
    overflow: hidden;
    height: 0;
  }

  &--open {
    > .Accordion__heading {
      .arrow img {
        transform: rotate(180deg);
      }
    }

    > .Accordion__body {
      height: auto;
    }
  }
}
