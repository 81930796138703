@import "styles/variables";

.DatePicker {
  display: flex;
  max-width: 390px;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: $bp-l) {
    flex-direction: column;
    gap: 15px;
    max-width: fit-content;
  }

  &__fild {
    height: 25px;
    display: flex;
    align-items: center;
  }

  &__fild::placeholder {
    color: #000;
    padding-left: 2px;
  }
}

.custom-input {
  display: flex;
  align-items: center;
  color: #2C44DA;
  font-family: Manrope, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  cursor: pointer;

  img {
    margin-left: 5px;
  }
}


.react-datepicker__month-container  {
  min-width: 100px;
}
