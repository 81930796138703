.layout {
  background: linear-gradient(180deg, #0017a6 10.22%, #3651ff 91.38%);
  height: 100dvh;
  display: flex;
  flex-direction: column;
}

.content {
  background-color: #fff;
  border-radius: 30px 30px 0 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: min(100%, 800px);
  margin: 0 auto;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  margin-bottom: 24px;
  padding: 0 24px;
  width: min(100%, 800px);
  margin: 0 auto 24px;

  span {
    a {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      gap: 4px;
    }

    img {
      margin-bottom: 0;
    }
  }

  img {
    margin-bottom: 16px;
  }

  h1 {
    margin-bottom: 4px;

    font-family: Geologica;
    font-weight: 600;
    font-size: 32px;
    line-height: 35.2px;
    letter-spacing: 0%;
    text-align: center;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
  }

  a {
    font-weight: 700;
  }
}

.completeBox {
  flex: 1;
  width: min(800px, 100%);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px;
}

:dir(rtl) {
  .headerContainer {
    span {
      img {
        rotate: 180deg;
      }
    }
  }
}
