@import 'styles/variables';

.input {
  display: block;

  .body {
    position: relative;
  }

  .label {
    color: $color-text-main;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  input {
    padding: 12px;
    background-color: #f3f4f7;
    border-radius: 8px;
    border: 1px solid #f3f4f7;

    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    transition: border-color 0.3s;

    &::placeholder {
      color: #999999;
      font-weight: 400;
    }

    &:read-only {
      color: #6e6e6e;
      cursor: default;
    }

    &:focus {
      border-color: #2c44da;
    }
  }

  .icon {
    position: absolute;
    right: 0;
    top: -3px;
    height: 24px;
    width: 24px;

    img,
    svg {
      height: 100%;
      width: 100%;
    }
  }

  .error {
    margin-top: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 19.6px;
    letter-spacing: 0%;

    color: #e00000;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .error .body {
    border-color: $color-error;
  }
}

.visibility {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 12px;
  top: 12px;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

:dir(rtl) {
  .input {
    .icon {
      right: unset;
      left: 0;
    }
  }

  .visibility {
    right: unset;
    left: 12px;
  }
}
