@import "styles/mixins";

.HomeLayout {
  font-family: Mulish, sans-serif;

  .container {
    max-width: 1368px;
    padding: 0 22px;
  }

  hr {
    background-color: #D4D4D4;
    border: none;
    height: 2px;
    width: 100%;
    display: block;
  }

  button {
    display: block;
    border: none;
    border-radius: 100px;
    padding: 12px 42px;
    font-size: 22px;
    cursor: pointer;
    transition: .3s;
    background-color: #2A84D5;
    color: #FFF;
    line-height: 1;

    b {
      font-weight: 800;
      font-size: 26px;
      display: block;
    }

    span {
      font-size: 20px;
    }

    &:hover {
      background-color: lighten(#2A84D5, 7%);
    }

    &:active {
      background-color: darken(#2A84D5, 10%);
    }
  }
}
