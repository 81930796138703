@import "styles/variables";

.Loader {
  position: relative;

  &__circle {
    width: 100%;
    height: 0;
    padding-bottom: calc(100% - 10px);
    border-width: 5px;
    border-style: solid;
    border-color: #E6EDFE #E6EDFE $color-primary #E6EDFE;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  &__logo {
    position: absolute;
    inset: 0;
    z-index: 1;
    margin: auto;
    font-size: 0;
    line-height: 0;
    width: 50%;
    height: 50%;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
