@import 'styles/variables';

.button {
  display: block;
  width: 100%;
  background: #2c44da;
  border: none;
  border-radius: 8px;
  padding: 18px 12px;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  transition: 0.3s;
  color: #fff;

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }

  &--filled {
    color: #fff;

    &.Button--black {
      background: $color-black;
    }

    &.Button--primary {
      background: $color-primary;
    }

    &:disabled {
      background: $color-placeholder;
    }

    &.Button--primary:disabled {
      background: #d5d6df;
      color: #2c44da99;
    }
  }

  &--text {
    &.Button--black {
      color: $color-black;
    }

    &.Button--primary {
      color: $color-primary;
    }

    &:disabled {
      color: $color-placeholder;
    }
  }

  .Loader {
    max-height: 18px;
    max-width: 18px;
    margin: auto;

    &__circle {
      border-width: 3px;
      padding-bottom: calc(100% - 6px);
    }

    &__logo {
      display: none;
    }
  }
}

.white {
  background-color: #fff;
  color: $color-primary;
}

.transparent {
  background-color: transparent;
  color: #fff;
}

.outlined {
  border-style: solid;

  color: $color-black;
  border-color: #eaebed;
  border-width: 1px;
  background-color: #fff;

  &.Button--black {
  }

  &.Button--primary {
    color: $color-primary;
    border-color: $color-primary;
    border-width: 1px;
  }

  &:disabled {
    color: $color-placeholder;
    border-color: $color-placeholder;
  }
}

.withIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

:dir(rtl) {
  .withIcon {
    flex-direction: row-reverse;
  }
}
