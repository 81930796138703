.container {
  position: relative;
}

.languages {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 999;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  transform: translateY(8px);
  width: min(calc(100dvw - (24px * 2)), 400px);
  animation: fadeIn 0.3s ease-out forwards;
}

.lang {
  background-color: transparent;
  text-align: start;
  padding-bottom: 12px;
  border-bottom: 1px solid #eaebed;

  cursor: pointer;

  &:last-child {
    border: none;
  }
}

.button {
  padding: 8px 16px;
  background: #ffffff1a;

  font-weight: 600;
  font-size: 14px;
  line-height: 18.2px;
  letter-spacing: 0%;

  color: #fff;
  text-transform: capitalize;

  border-radius: 100px;

  display: flex;
  align-items: center;
  gap: 4px;

  cursor: pointer;

  img {
    transition: transform 0.3s;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

:dir(rtl) {
  .languages {
    right: unset;
    left: 0;
  }
}
