@import "styles/variables";

.StatisticsPage {
  background-color: #F2F2F2;
  min-height: 100vh;

  @media (max-width: $bp-l) {
    height: 100%;
  }
    
  &__container{
    max-width: 1280px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 28px;

    @media (max-width: $bp-m) {
      padding: 0 15px;
    }
  }
    
}