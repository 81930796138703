@import 'styles/variables';

.label {
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 19.6px;
    letter-spacing: 0%;
    color: $color-main;
  }
}

.inputs {
  display: grid;
  gap: 8px;
  max-width: 400px;
}

.input {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 12px 22px;
  font-family: Geologica;
  font-weight: 600;
  font-size: 24px;
  line-height: 26.4px;
  border: 1px solid #f3f4f7;
  background-color: #f3f4f7;
  border-radius: 8px;
  color: $color-main;

  transition: border-color 0.3s;

  &:focus {
    border-color: $color-primary;
  }
}

.error {
  color: #e00000 !important;
}
