@import "styles/variables";

.EventsFilter {
  &__title {
    font-weight: 700;
    font-size: 16px;
    color: #000;
    margin-bottom: 12px;
  }

  &__accordions {
    .Accordion {
      &.country {
        > .Accordion__heading .title {
          font-weight: 800;
        }
      }

      &.event {
        .Accordion__heading {
          background-color: #FAFBFB;

          .title img {
            font-size: 0;
            width: 14px;
            height: 14px;
            margin-right: 8px;
          }
        }
      }

      &.nesting-2 {
        .Accordion__heading {
          padding-left: 32px;
        }
      }

      &.nesting-3 {
        .Accordion__heading {
          padding-left: 48px;
        }
      }

      &.active {
        .Accordion__heading {
          background-color: $color-primary;
          color: #FFF;
          font-size: 14px;
          font-weight: 800;

          svg {
            fill: white;
          }
        }
      }
    }
  }
}

:dir(rtl) {
  .EventsFilter__accordions .Accordion {
    img:not(.arrow > img) {
      margin-right: 0;
      margin-left: 8px;
    }
  }
}
