
.date {
  max-width: 75px;
}

.email {
  max-width: 190px;
}

.eventInfo {
  width: 150px;
}