@import "./variables";

@import "./mixins";

@import "./fonts";

*, *::before, *::after {
  box-sizing: border-box;
}

:root {
  --toastify-color-success: $color-success;
  --toastify-color-error: $color-error;
}

html {
  body {
    box-sizing: border-box;
    font-weight: 400;
    font-family: Manrope, sans-serif;

    &:dir(rtl) {
      font-family: Roboto, sans-serif;
    }
  }
}

a {
  text-decoration: none;
  color: inherit;

  &:visited {
    color: inherit;
  }
}

.container {
  width: 100%;
  max-width: $max-content-width;
  padding: 0 24px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

h2 {
  font-family: "Geologica", sans-serif;
  font-weight: 700;
  font-size: 35px;
  line-height: 1.1;
  text-transform: uppercase;
}

input {
  &:focus {
    outline: none;
  }
}

img {
  display: inline-block;
  max-width: 100%;
}

.disable-scroll {
  overflow: hidden;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }
}

.scroll-to-top {
  position: fixed;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  bottom: 60px;
  right: 60px;
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
  background-color: $color-bg;
  opacity: 0;
  transition: opacity .5s;

  img {
    width: 100%;
    height: 100%;
  }

  &.show {
    opacity: 1;
  }

  @media (max-width: $bp-s) {
    bottom: 30px;
    right: 30px;
  }
}

#react-modals {
  width: 100%;
  height: 100%;
  content: "";
  display: block;
  position: fixed;
  z-index: 1002;
  transform: translate(100%, 0);
  transition: 0s .3s;

  &.open {
    transform: translate(0, 0);
    transition: 0s;
  }
}

.OldHeader {
  &--default {
    .OldHeader__navigation {
      margin-left: auto;
    }

    .OldHeader__burger {
      margin-left: auto;
    }
  }

  &--home {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;

    > .container {
      justify-content: space-between;
    }

    .OldHeader__navigation {
      margin: auto;
      background-color: #2D3436;
      background-image: linear-gradient(315deg, #2D3436 0%, #000 74%);
      color: #FFFFFF;
    }

    .LanguageSwitcher {
      color: #FFFFFF;
    }

    .Menu {
      color: #000000;
    }

    .OldHeader__burger {
      margin-left: 24px;
    }

    &.subPage {
      top: 0;
      position: relative;
      background: black;

      .OldHeader__navigation {
        margin: 0;
      }

      .LanguageSwitcher.show-desktop {
        display: inline-block;
        margin: 0 0 0 16px;
      }

      .LanguageSwitcher.show-mobile {
        display: none;
      }

      .OldHeader__links {
        margin-left: auto;
      }
    }
  }

  &--event {
    .OldHeader__logo {
      margin: 0;
    }

    .OldHeader__faq-link {
      margin-left: 10px;
    }

    div.LanguageSwitcher.show-desktop {
      display: inline-block;
      margin-top: 0;
      margin-left: 10px;
    }
  }

  &--admin {
    .OldHeader__logo {
      margin-right: auto;
    }
  }

  @media (max-width: $bp-l) {
    &--home {
      &.subPage {
        .OldHeader__navigation {
          position: relative;
          display: block;
          width: auto;
          height: auto;
          top: unset;
          padding: 0;
          background: transparent;
          margin-right: 2px;
        }

        .BurgerButton {
          display: none;
        }
      }
    }
  }

  @media (min-width: $bp-l) {
    &--home {
      top: 50px;

      .OldHeader__navigation {
        background: transparent;
      }
    }

    &--event {
      .OldHeader__faq-link {
        margin-left: 20px;
      }
    }
  }
}

.TechnicalPage {

  @include body-height;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  width: 100%;
  max-width: 400px;

  h2 {
    margin-bottom: 48px;
    font-family: Manrope, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 1.2;
  }

  form {
    width: 100%;
    max-width: 420px;
    display: flex;
    flex-direction: column;

    .input {
      input {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.2;
      }

      & + .input {
        margin-top: 16px;
      }
    }

    .link {
      color: $color-primary;
      margin-top: 16px;
      font-size: 14px;
      font-weight: 600;
    }

    .submit {
      margin-top: 48px;
    }
  }
}
