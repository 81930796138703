@import "variables";

// Header
@mixin header-height() {
  height: 72px;

  @media (min-width: $bp-s) {
    height: 88px;
  }
}

@mixin body-height($offset: "") {

  @if $offset != "" {
    height: calc(100vh - 72px - $offset);
  }

  @else {
    height: calc(100vh - 72px);
  }

  @media (min-width: $bp-s) {

    @if $offset != "" {
      height: calc(100vh - 88px - $offset);
    }

    @else {
      height: calc(100vh - 88px);
    }
  }
}

@mixin transform($how) {
  -webkit-transform: $how;
  -moz-transform: $how;
  -o-transform: $how;
  transform: $how;
}

@mixin transition($how) {
  -webkit-transition: $how;
  -moz-transition: $how;
  -o-transition: $how;
  transition: $how;
}
