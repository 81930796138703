@import 'styles/variables';

.registerPage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  flex: 1;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.link {
  font-weight: 700;
  color: $color-primary;

  &:visited {
    color: $color-primary;
  }
}
