@import "styles/variables";

.StatisticsFilter {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  max-width: 860px;
  width: 100%;

  @media (max-width: $bp-l) {
    flex-direction: column;
  }

  .button {
    background-color: transparent;
    color: #6E6E6E;
    font-family: Manrope, sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.2;
    cursor: pointer;

    &:hover {
      color: #2C44DA;
    }

    @media (max-width: $bp-l) {
      max-width: 100px;
      margin-top: 15px;
      text-align: left;
    }
  }
}

hr {
  border-color: rgb(247 250 252 / 36%);
  margin: 0;
}

.select > option:nth-child(2){
  font-style: italic;
  font-weight: 600;
}
