.intro {
  position: fixed;
  inset: 0;
  background: linear-gradient(180deg, #0017a6 10.22%, #3651ff 91.38%);
  z-index: 1002;
  padding: 24px;

  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s;
}

.content {
  position: absolute;
  height: calc(100% - 24px * 2);
  width: min(800px, calc(100dvw - 24px * 2));
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-height: 714px) {
    overflow-y: scroll;
  }
}

.languages {
  align-self: flex-end;
}

.labels {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 40px;

  p {
    display: flex;
    align-items: center;
    gap: 4px;

    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0%;
    color: #fff;

    background-color: #ffffff1a;

    border-radius: 100px;
    padding: 4px 8px;
  }
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 8px;
  flex: 1;
}

.opened {
  opacity: 1;
  pointer-events: all;
}
