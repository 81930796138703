.separator {
  position: relative;
  height: 1px;
  width: 100%;
  background-color: #eaebed;
  margin: 12px 0;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    background-color: #fff;
    padding: 0 24px;
    width: max-content;
  }
}
