/* Breakpoints */
$bp-xs: 414px;
$bp-s: 576px;
$bp-m: 768px;
$bp-l: 992px;
$bp-xl: 1200px;
$bp-xxl: 1400px;
$max-content-width: 1168px;

/* Colors */
$color-primary: #2c44da;
$color-text-main: #171a1c;
$color-text-secondary: #4d4d4d;
$color-black: #000;
$color-placeholder: #d2d2d2;
$color-bg: #f3f4f6;
$color-error: #ff6363;
$color-success: #81b24f;
$color-warning: #f1c40e;
$color-main: #171a1c;

/* Map colors */
$visited-color: #278024;
$just-visited-color: #ff9315;
$not-visited-color: #f74e38;
$expired-color: #aeaeae;
