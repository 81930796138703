@import 'styles/variables';

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  flex: 1;

  padding: 32px 24px;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.link {
  font-weight: 700;
  color: $color-primary;

  &:visited {
    color: $color-primary;
  }
}

.resend {
  font-family: Manrope;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0%;

  button {
    font-family: Manrope;
    font-weight: 800;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0%;

    cursor: pointer;
    border: none;
    background-color: transparent;
  }
}
