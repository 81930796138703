@import "styles/variables";

.accordion {
  background-color: #FFF;
  height: 25px;

  @media (max-width: $bp-l) {
    max-width: 290px;
    margin-bottom: 15px;
  }

  &__title {
    color: #000;
    font-family: Manrope, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    width: 290px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    cursor: pointer;
  }

  &__icon {
    img {
      transition: 0.3s ease-in-out;
      cursor: pointer;
    }
  }

  &.active &__icon {
    img {
      transform: rotate(180deg);
      cursor: pointer;
    }
  }

  &__content {
    padding: 0 0.5rem;
    max-height: 0;
    overflow-y: auto;
    transition: 0.4s ease-in-out;
    background-color: #FFF;
    position: absolute;
    z-index: 100;
  }

  &.active &__content {
    padding: 1.5rem 0.5rem;
    max-height: 600px;
    box-shadow: rgb(0 0 0 / 10%) 0 20px 25px -5px, rgb(0 0 0 / 4%) 0 10px 10px -5px;
  }
}
