.modal {
  position: fixed;
  inset: 0;
  z-index: 1002;
  background-color: #00000080;

  opacity: 0;
  pointer-events: none;

  transition: opacity 0.4s;
}

.element {
  position: absolute;
  width: 100%;
  bottom: 0;
  top: 16px;
  background-color: #fff;
  border-radius: 8px 8px 0 0;

  padding: 24px;

  display: flex;
  flex-direction: column;

  h2 {
    font-family: Geologica;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0%;
  }

  h4 {
    font-family: Manrope;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
  }
}

.close {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 28px;
  right: 24px;

  z-index: 1003;

  cursor: pointer;
}

.opened {
  opacity: 1;
  pointer-events: all;
}

.openedElement {
  animation: slideIn 0.6s ease-out forwards;
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

:dir(rtl) {
  .close {
    left: 24px;
    right: unset;
  }
}
