.FullScreenLoader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1010;
  width: 100vw;
  height: 100vh;
  background: rgb(0 0 0 / 80%);

  & > div {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%);
  }

  .Loader {
    width: 150px;
  }
}
