@import "styles/variables";

.BasicTable {
  margin-top: 16px;
  width: 100%;

  &__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    @media (max-width: $bp-s) {
      flex-direction: column;
      align-items: flex-start;
    }

    &--title {
      color: #000;
      font-family: Manrope, sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.2;
      width: fit-content;

      @media (max-width: $bp-s) {
        margin-bottom: 10px;
      }
    }
  }

  &__control {
    display: flex;
    align-items: center;

    @media (max-width: $bp-s) {
      width: 100%;
      justify-content: space-between;
    }

    &--nextAndPrev {
      display: flex;
      width: 110px;
      align-items: center;
      justify-content: space-between;
      margin-right: 20px;

      button {
        cursor: pointer;
      }

      p {
        font-size: 13px;
        font-weight: 600;
        font-family: Manrope, sans-serif;
        margin: 0 7px;
      }
    }
  }
}

.table {
  font-family: "Geologica", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td,
table th {
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Manrope, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
  vertical-align: middle;
}

table th {
  color: #6F6F6F;

  @media (max-width: $bp-l) {
    display: none;
  }
}

.table td {
  color: #000;
  word-break: break-all;

  @media (max-width: $bp-l) {
    display: flex;
    text-align: right;
    justify-content: flex-end;
    min-height: 40px;
    padding-right: 15px;
    border-bottom: 1px solid rgb(224 224 224);
  }
}

.table td::before {

  @media (max-width: $bp-l) {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: 600;
    font-size: 13px;
    text-align: left;
    top: 50%;
    transform: translate(0, -50%);
  }
}

table th:nth-child(1),
table td:nth-child(1) {
  padding-left: 40px;
}

table tbody tr {

  @media (max-width: $bp-l) {
    display: flex;
    border: 1px solid black;
    flex-direction: column;
    height: auto;
    margin-bottom: 15px;
    background-color: #F2F2F2;
  }
}

table tr:nth-child(odd) {
  background-color: #FFF;
}

table tr:nth-child(even) {
  background-color: #F7F7F7;
}

table tr:hover {
  background-color: #DDD;
}

nav {
  display: block;
}

.pagination {
  display: flex;
  list-style: none;
}

.pagination-button {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  color: #000;
  font-family: Manrope, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.2;
  cursor: pointer;
  background-color: transparent;
}

.page-button :hover {
  background-color: #2C44DA;
}

.page-item-active {
  background-color: #2C44DA;
  color: #FFF;
}
